<template>
    <div class="app">
        <div class="Background" style="min-height: auto">
            <div class="well top"
                 style="
          background: #00589d;

          padding-top: 0.4% !important;
          padding-bottom: 0.4% !important;
        ">
                <a href="/" style="margin-top: 5px" class="logo-a">
                    <img class="inline vertically-middle logo-img"
                         src="./../assets/logo.png"
                         style="width: calc(4vw + 0.1vh + 2vmin); margin-top: 2px; margin-bottom: 2px" />
                </a>
                <div class="inline-table vertically-super LeftPadding10 titleDivBlock"
                     style="padding-bottom: 3px">
                    <p class="margin-zero title-heading"
                       style="
              font-size: calc(1vw + 0.1vh + 2vmin);
              padding-top: 0%;
              padding-bottom: 0%;
              font-weight: 600;
              margin-top: -13px;
            ">
                        Screen Cast
                    </p>
                    <p class="title-sub-heading"
                       style="
              font-size: calc(1vw + 0.1vh + 1vmin);
              padding-bottom: 1px;
              margin: 0%;
              margin-top: -6px;
            ">
                        {{ view_screen }}
                    </p>
                </div>
                <div style="float: right; padding-top: 15px"
                     class="paddingRight localization-opendiv">
                    <div class="localization-dropdown-wrapper" style="z-index: 999">
                        <span class="language-label-root" style="color: rgb(255, 255, 255);font-size:calc(1vw + 0.1vh + 1vmin)">
                            {{ lang }}
                        </span>
                        <select class="language-select-dropdown-root"
                                style="
                color: black;
                margin-right: 1vw;
                width: calc(9vw + 0.1vh + 2vmin);
                border: 1px solid black;
              "
                                @change="languageChange"
                                v-model="LangCode">
                            <option value="0" style="color: black" selected>English</option>
                            <option value="1" style="color: black">Deutsch</option>
                            <option value="2" style="color: black">Français</option>
                            <option value="3" style="color: black">Español</option>
                            <option value="4" style="color: black">Português</option>
                            <option value="5" style="color: black">Italiano</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row_login">
                <div class="column_login col1"
                     style="
            width: 55%;
            padding-left:calc(5.5vw + 0.1vh + 2vmin)
          ">
                    <div class="notes"
                         style="
              margin-right: 0px;

              margin-top: 0px;
            ">
                        <!-- <p class="helo1">Heloo mat</p>
                        <p class="helo2">Heloo mat</p> -->

                        <p class="normal textheader"
                           style="font-size: calc(1.3vw + .1vh + 1vmin); line-height: 20px; font-weight: 600;line-height:2.1vw">{{ screen_cast_desc_1 }}.</p>
                        <!-- <span class="" style="font-size:1.2rem;padding-top:88%!important">
                                      {{ippc_desc_1}}
                                  </span> -->
                        <!-- <p class="normal textsubheader" style="font-size:1.3rem;font-weight:700">{{ ippc_desc_1 }}</p> -->

                        <div style="margin-top: 5px"></div>
                        <span class="bold textsubheader1" style="font-size: calc(1.3vw + .1vh + .4vmin)">
                            {{
              screen_cast_desc_4
                            }}
                        </span>
                        <div style="margin-top: 5px"></div>
                        <table>
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <td class="bullet"></td>
                                <td class="datalist">
                                    {{ Launch_sc }}
                                    <a :href="playStoreLink"
                                       target="_blank">Screen Cast</a>
                                    {{ Launch_sc1 }}
                                </td>
                            </tr>
                            <tr>
                                <td class="bullet"></td>
                                <td class="datalist">{{ screen_cast_how_2 }}</td>
                            </tr>
                            <tr style="padding-top: 20px" v-show="isloading">
                                <td class="bullet"></td>
                                <td class="datalist">{{ screen_cast_how_3 }}</td>
                            </tr>
                            <!-- <tr v-show="!isloading">
                              <td class="bullet"></td>
                              <td class="datalist"> {{ screen_cast_how_31 }}</td>
                            </tr> -->
                            <tr>
                                <td class="bullet"></td>
                                <td class="datalist">{{ screen_cast_how_4 }}</td>
                            </tr>
                            <!-- <tr>
                              <td class="bullet"></td>
                              <td class="datalist">{{ screen_cast_how_5 }}</td>
                            </tr> -->
                            <tr></tr>
                        </table>

                        <div style="margin-top: 17px"></div>
                        <span class="">
                            <a :href="videolink"
                               target="_blank"
                               style="font-size:calc(1.2vw + .1vh + .4vmin); margin-left:2vw"
                               class="watchTutoriallink">{{ WatchVideoT }}</a>
                        </span>
                    </div>
                </div>
                <div class="column_login col2" style="width: 40%; margin-bottom: 0%">
                    <div class="login-panel_bar"
                         style="
              border-radius: 5%;
              padding-right: 0px;
            ">
                        <div class="innerlogin" style="margin-right: 20px;text-align:center">
                                <p class="normal scanQrText scanQrTextdisplay1"
                                   v-show="isloading"
                                   style="
                    color: #00589d;
                    margin-bottom: 5% !important;
                    width: 21vw;
                  ">
                                    {{ scan_qr }}
                                </p>
                                <qrcode-vue :value="myQRValue1 + myQRValue"
                                            :size="sizeValue"
                                            style="width: 9vw; height: auto; margin:auto"
                                            level="H"
                                            v-show="isloading" />
                            <p class="paraclass"
                               v-show="isloading"
                               style="margin: 0px; margin-top: 3.5%"></p>
                            <p class="normal scanQrText"
                               v-show="isloading"
                               style="color: #00589d; margin-bottom: 2.5%">
                                {{ or_enter_id }}
                            </p>
                            <div style="text-align:center">
                                    <input type="text"
                                           :placeholder="id"
                                           id="username user_username"
                                           class="inpt-txt LoginTbx"
                                           v-model="username"
                                           autocomplete="off"
                                           @paste="onPaste"
                                           style="
                      margin-bottom: 10px;
                      border-radius: 6px;
                      padding-start: 5px;
                    " />

                                    <input type="password"
                                           :placeholder="password"
                                           v-model="userpass"
                                           id="userpass"
                                           class="inpt-txt LoginTbx"
                                           autocomplete="on"
                                           style="border-radius: 6px; padding-start: 5px" />
                                <br />
                                <br />
                                <div style="text-align:center">
                                        <button type="submit"
                                                @click="performSignIN()"
                                                class="LoginButton">
                                            {{ login }}
                                        </button>
                                </div>
                                <div style="color: red;text-align:center">
                                        <span id="p1" style="font-size: calc(0.7vw + 0.1vh + 1vmin)">{{ error_msg }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="foto"
             style="
        padding-top: 0% !important;
        border-top: 1px solid #c0c0c0;

        margin-top: 15px;
        width: 100%;
        margin-bottom: 0% !important;
        position: absolute;
        bottom: 5px;
      ">
            <div align="center"
                 style="
          padding: 1%;
          margin-bottom: 0% !important;
          padding-bottom: 0% !important;
        ">
                <ul class="footerUlList"
                    style="
            margin: auto;
            margin-left: -40px;
            padding: auto;
            overflow: hidden;
            list-style-type: none;

          ">
                    <li class="foot-list" style="margin-left: 1.2%">
                        <a class="foot-a" :href="HomeLink" target="_blank">{{ Home1 }}</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href="PurchaseLink" target="_blank">
                            {{
              Purchase
                            }}
                        </a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href="DownloadLink" target="_blank">
                            {{
              Download
                            }}
                        </a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href="SupportLink" target="_blank">
                            {{
              Support
                            }}
                        </a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href="ResourcesLink" target="_blank">
                            {{
              Resources
                            }}
                        </a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href="AboutDeskshareLink" target="_blank">{{ AboutDeskshare }}&nbsp;DeskShare</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href="PrivacyPolicyLink" target="_blank">
                            {{
              PrivacyPolicy
                            }}
                        </a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href="ContactUsLink" target="_blank">
                            {{
              ContactUs
                            }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script src="./../js/Home.js"></script>