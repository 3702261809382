import "@/assets/css/bootstrap.css";
import "@/assets/css/login.css";
import axios from "axios";
import QrcodeVue from "qrcode.vue";
import constants from "./../Constants/Constansts.js";
import translate from "./../translations/global.json";
import image_en from "./../assets/screen_en.png";
import image_de from "./../assets/screen_de.png";
import image_it from "./../assets/screen_it.png";
import image_pt from "./../assets/screen_pt.png";
import image_es from "./../assets/screen_es.png";
import image_fr from "./../assets/screen_fr.png";

export default {
    name: "Home",
    data: function () {
        return {
            videolink: null,
            myQRValue: "null",
            myQRValue1: "https://play.google.com/store/apps/details?id=com.screencast#",
            seen: false,
            lang: "Language:",
            error_msg: "",
            screen_cast: "Screen Cast",
            view_screen: "View and control your Android mobile device from a web browser",
            screen_cast_desc_1: "Screen Cast let you view and control your Android mobile phone from a web browser",
            screen_cast_desc_2: "Screen Cast is great to project your videos and pictures, view your mobile app, or display your",
            screen_cast_desc_3: "",
            screen_cast_desc_4: "How does it work?",
            screen_cast_how_1: "1. Launch the Screen Cast app on your Android Mobile Device.",
            screen_cast_how_2: "2. Simply scan the QR code, or enter your security credentials from the application.",
            screen_cast_how_3: "3. Start viewing your Mobile Screen on this device.",
            scan_qr: "Scan QR Code to view your Mobile Screen",
            or_enter_id: "OR Enter the ID and Password",
            id: "ID:",
            password: "Password:",
            POSTER_IMAGE: image_en,
            login: "Login",
            errorCode: null,
            sizeValue: 150,
            username: null,
            userpass: null,
            qrCodeRequest: 0,
            isloading: false,
            LangCode: 0,
            refreshTime: 0,
            poll: 0,
            version: 236, //minimum apk version for web view as of 8/22
        };
    },

    created: function () {
        if(!localStorage.getItem(constants.TOKEN))
            localStorage.removeItem("version")
        this.getQRCodeID();
        document.body.style.backgroundColor = "#dbf0fe";

        var getValue = localStorage.getItem(constants.LANGUAGE_CODE);
        this.LangCode = getValue;
        if (getValue == null) {
            localStorage.setItem(constants.LANGUAGE_CODE, 0);
            this.LangCode = 0;
        }
        this.onLanguagechange()


        var myInterval = setInterval(() => {
            this.getUserDetails()
        }, 7000);

        setTimeout(function () {
            clearInterval(myInterval);
        }, 30000);
        
        if (localStorage.getItem(constants.TOKEN)) {
            axios.get(constants.URL + "/networktest?ver=" + localStorage.getItem(constants.TOKEN))
            .then(()=>{
                this.$router.replace("/About");
            })
        }
    },
    mounted: function () {
        this.reloadon()
    },

    components: {
        QrcodeVue,
    },

    methods: {
        languageChange() {
            localStorage.setItem(constants.LANGUAGE_CODE, event.target.value);
            this.onLanguagechange();

        },

        reloadon() {
            if (localStorage.getItem("reloadonce") == null || localStorage.getItem("reloadonce") == 0) {
                location.reload();
            }
            localStorage.setItem("reloadonce", 1);
        },
        performSignIN() {
            if(localStorage.getItem(constants.TOKEN))
                this.$router.replace("/About");
            var langCode = localStorage.getItem(constants.LANGUAGE_CODE);
            this.login = translate.Loggingin[langCode];
            if (this.username != null && this.userpass != null) {
                const body = {
                    username: this.username.trim(),
                    password: this.userpass.trim(),
                    language: localStorage.getItem("languageCode") == null ?
                        "en" : localStorage.getItem("languageCode"),
                };
                // SignIN success
                axios
                    .post(constants.URL + "/login", body)
                    .then((response) => {
                        if (response.data['version'] >= this.version) {
                            localStorage.setItem("version", true)
                        }
                        if(response.data['android_version'] >= 33) //version code 33 = Android 13
                            localStorage.setItem("isAndroid13",true)
                        localStorage.removeItem("IsNavigationPresent")
                        if(response.data['navigation']==2){
                            localStorage.setItem("IsNavigationPresent", true)
                        }
                        if (response.status == 200) {
                            localStorage.setItem(constants.TOKEN, response.data.jwt);
                            this.$router.replace("/About");
                        }
                    })
                    // SignIN error
                    .catch((error) => {
                        this.login = translate.Login[langCode];
                        this.errorCode = error.response.status;
                        switch (error.response.status) {
                            case 400:
                                this.error_msg =
                                    translate.Pleasespecifyvalidusername[
                                    langCode
                                    ];
                                break;

                            case 401:
                                this.error_msg = translate.Pleasespecifyvalidpassword[langCode];
                                break;

                            case 404:
                                this.error_msg =
                                    translate.PleasecheckyourInternetconnectionandtryagain[
                                    langCode
                                    ];
                                break;
                        }
                    });
            } else {
                this.errorCode = 101;
                this.error_msg =
                    translate.EntertheIDandPasswordgiveninScreenCast[langCode];
                this.login = translate.Login[langCode];
            }
        },
        // QRCode generate unique string 
        getQRCodeID() {
            axios
                .post(constants.URL + "/unique")
                .then((response) => {
                    this.isloading = true;
                    this.myQRValue = response.data.message;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // generate username and password in QRCode 
        getUserDetails() {

            const uniqueI = {
                uniqueId: this.myQRValue,
            };
            axios
                .post(constants.URL + "/qrData", uniqueI)
                .then((response) => {
                    this.username = response.data.data[0].username
                    this.userpass = response.data.data[0].pass
                    this.qrDelete(uniqueI)
                    this.performSignIN()
                    clearInterval(this.interval);
                })
                .catch(() => { });
        },
        // Delete uniqueID once working is done
        qrDelete(uniqueI) {
            axios
                .post(constants.URL + "/qrDeleteId", uniqueI)
                .then(() => { })
                .catch(() => { });
        },
        // translation scripts with dropdown
        onLanguagechange() {
            var langCode = localStorage.getItem(constants.LANGUAGE_CODE);
            if (langCode == null) {
                langCode = 0;
                localStorage.setItem(constants.LANGUAGE_CODE, 0);
            }
            this.screen_cast = translate.ScreenCast[langCode];
            this.view_screen = translate.CastyourmobilescreentoyourPCMacTabletorSmartTV[langCode];
            this.screen_cast_desc_1 = translate.text[langCode];
            this.screen_cast_desc_2 = translate.text1[langCode];
            this.screen_cast_desc_4 = translate.text3[langCode];
            this.screen_cast_how_1 = translate.text4[langCode];
            this.screen_cast_how_2 = translate.text5[langCode];
            this.screen_cast_how_3 = translate.text6[langCode];
            this.screen_cast_how_4 = translate.text7[langCode];
            this.screen_cast_how_5 = translate.text8[langCode];
            this.scan_qr = translate.ScanQRCodetoviewyourMobileScreen[langCode];
            this.or_enter_id = translate.EntertheIDandPasswordgiveninScreenCast[langCode];
            this.id = translate.EnterID[langCode];
            this.password = translate.EnterPassword[langCode];
            this.login = translate.Login[langCode];
            this.lang = translate.ScreenCastisavailablein[langCode];
            this.videolink = translate.videolink[langCode];
            this.Launch_sc = translate.LaunchSC[langCode];
            this.Launch_sc1 = translate.LaunchSC1[langCode];
            this.playStoreLink = translate.playStoreLink[langCode];
            this.WatchVideoT = translate.WatchVideoT[langCode];
            this.screen_cast_how_31 = translate.text10[langCode];
            this.Home1 = translate.Home1[langCode]
            this.Purchase = translate.Purchase[langCode]
            this.Download = translate.Download[langCode]
            this.Support = translate.Support[langCode]
            this.Resources = translate.Resources[langCode]
            this.AboutDeskshare = translate.AboutDeskshare[langCode]
            this.PrivacyPolicy = translate.PrivacyPolicy[langCode]
            this.ContactUs = translate.ContactUs[langCode]
            this.HomeLink = translate.HomeLink[langCode]
            this.PurchaseLink = translate.PurchaseLink[langCode]
            this.DownloadLink = translate.DownloadLink[langCode]
            this.SupportLink = translate.SupportLink[langCode]
            this.ResourcesLink = translate.ResourcesLink[langCode]
            this.AboutDeskshareLink = translate.AboutDeskshareLink[langCode]
            this.PrivacyPolicyLink = translate.PrivacyPolicyLink[langCode]
            this.ContactUsLink = translate.ContactUsLink[langCode]


            switch (this.errorCode) {
                case 101:
                    this.error_msg =
                        translate.EntertheIDandPasswordgiveninScreenCast[langCode];
                    break;

                case 400:
                    this.error_msg =
                        translate.Pleasespecifyvalidusername[langCode];
                    break;

                case 401:
                    this.error_msg = translate.Pleasespecifyvalidpassword[langCode];
                    break;

                case 404:
                    this.error_msg =
                        translate.PleasecheckyourInternetconnectionandtryagain[langCode];
                    break;
            }
            // Display Error message image when braodcasting is stop 
            if (langCode == 0) {
                this.POSTER_IMAGE = image_en;
            } else if (langCode == 1) {
                this.POSTER_IMAGE = image_de;
            } else if (langCode == 2) {
                this.POSTER_IMAGE = image_fr;
            } else if (langCode == 3) {
                this.POSTER_IMAGE = image_es;
            } else if (langCode == 4) {
                this.POSTER_IMAGE = image_pt;
            } else if (langCode == 5) {
                this.POSTER_IMAGE = image_it;
            } else {
                this.POSTER_IMAGE = image_en;
            }
            this.seen = false;
        },
    },
};